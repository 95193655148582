import {computed, Injectable, OnDestroy, signal, Signal, WritableSignal} from '@angular/core';
import {AppQuery} from '../../queries/app.query';
import {AppService} from '../app/app.service';
import {ServerAddress} from '../../models/server-address';
import {Subscription} from 'rxjs';
import {Notification} from '../../bfa-api/models/notification';
import {AuthQuery} from '../../queries/auth.query';
import {distinctUntilChangedObject} from '../../util/distinct-until-changed-object';

@Injectable({
    providedIn: 'root'
})
export class NotificationService implements OnDestroy {
    private readonly hasReadNotificationInSession: WritableSignal<boolean>;
    private selectedServer: ServerAddress | undefined;
    private subscriptions: Subscription;
    private readonly currentNotification: WritableSignal<Notification|undefined>;

    constructor(
        private appQuery: AppQuery,
        private appService: AppService,
        private authQuery: AuthQuery,
    ) {
        this.appService = appService;
        this.subscriptions = new Subscription();
        this.hasReadNotificationInSession = signal(false);
        this.currentNotification = signal(undefined);

        this.subscriptions.add(this.appQuery.selectedServer$.pipe(distinctUntilChangedObject()).subscribe((selectedServer) => {
            this.currentNotification.set(selectedServer?.serverInformation?.notification ?? undefined)
            this.selectedServer = selectedServer;
        }));
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public getCurrentNotification(): Signal<Notification | undefined> {
        return this.currentNotification;
    }

    public getShowNotification(): Signal<boolean> {
        return computed(() => {
            return !(!this.currentNotification() || this.selectedServer?.notificationReadByUserIds.includes(this.authQuery.getUserId()) || this.hasReadNotificationInSession());
        })
    }

    public setHasReadNotificationInSession(hasReadNotificationInSession: boolean): void {
        this.hasReadNotificationInSession.set(hasReadNotificationInSession);
    }

    public updateNotification(): void {
        if (!this.selectedServer || this.selectedServer?.currentNotificationId === this.selectedServer?.serverInformation.notification?.notificationId) {
            return;
        }

        const updatedServerAddress = structuredClone(this.selectedServer);

        updatedServerAddress.currentNotificationId = this.selectedServer.serverInformation.notification?.notificationId ?? undefined;
        updatedServerAddress.notificationReadByUserIds = [];

        this.appService.updateServers(this.appQuery.getServers()
            .map((serverAddress) => serverAddress.selected ? updatedServerAddress : serverAddress));
    }

    public markNotificationAsRead(): void {
        if (this.selectedServer) {
            const updatedServerAddress = structuredClone(this.selectedServer);

            updatedServerAddress.notificationReadByUserIds = [...this.selectedServer.notificationReadByUserIds, this.authQuery.getUserId()];

            this.appService.updateServers(this.appQuery.getServers()
                .map((server) => server.selected ? updatedServerAddress : server));
        }
    }
}
