import {Component, OnDestroy} from '@angular/core';
import {BasicSubscribableComponent} from '../dummy-components/basic-subscribable-component';
import {ContactService} from '../../services/contact/contact.service';
import {ContactListItem} from '../../bfa-api/models/contact-list-item';
import {DialogService} from '../../services/dialog/dialog.service';
import {BehaviorSubject} from 'rxjs';
import {ListItem} from '../../models/list/list-item.model';
import {AppQuery} from '../../queries/app.query';
import {ListItemType} from '../../types/list-item.type';
import {LIST_ITEM_TYPE} from '../../constants/list-item-type.constants';
import {STATIC_CONFIGS} from '../../../configs/static.config';

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.css']
})
export class ContactsComponent extends BasicSubscribableComponent implements OnDestroy {
    protected contactListIsEmpty: boolean;
    protected contactListItems: Array<ListItem>;
    protected contactListItems$: BehaviorSubject<Array<ListItem>>;
    protected isLoading: boolean;
    protected listItemType$: BehaviorSubject<ListItemType>;
    protected showBeginnersHelp!: boolean;
    protected showListFilter: boolean;

    constructor(
        private appQuery: AppQuery,
        private contactService: ContactService,
        private dialogService: DialogService,
    ) {
        super();
        this.contactListItems = [];
        this.contactListItems$ = new BehaviorSubject<Array<ListItem>>([]);
        this.contactListIsEmpty = true;
        this.isLoading = false;
        this.listItemType$ = new BehaviorSubject<ListItemType>(LIST_ITEM_TYPE.SIMPLE);
        this.showListFilter = false;

        this.subscriptions.add(this.appQuery.preferences$.subscribe(preferences => {
            this.showBeginnersHelp = preferences.showBeginnersHelp;
        }));

        this.subscriptions.add(this.contactListItems$.subscribe(this.updateListItems));
        this.fetchViewData();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    public fetchViewData = (): void => {
        this.isLoading = true;
        this.contactService.fetchContactListViewData()
            .then(contactList => {
                const contactListItems = contactList.items as ContactListItem[];

                contactListItems.sort((firstItem: ContactListItem, secondItem: ContactListItem) => {
                    return firstItem.userFullName.localeCompare(secondItem.userFullName);
                });

                this.contactListItems$.next((contactListItems)
                    .map(this.contactService.parseContactListItemToListItem));
            })
            .catch((error: Error) => {
                this.dialogService.showError('CONTACTS_LOADING_UNEXPECTED_ERROR_MSG', (error as Error));
            })
            .finally(() => {
                this.contactListIsEmpty = this.contactListItems$.value.length === 0;
                this.isLoading = false;
            });
    };

    public pullList(): void {
        if (this.contactListItems.length >= STATIC_CONFIGS.ui.minListItemsForListFilter) {
            if (this.showListFilter) {
                this.fetchViewData();
            }

            this.showListFilter = !this.showListFilter;
        } else {
            this.fetchViewData();
        }
    }

    public rightClickPullList(): void {
        this.fetchViewData();
        this.closeFilter();
    }

    public closeFilter(): void {
        this.showListFilter = false;
    }

    public updateListItems = (listItems: Array<ListItem>): void => {
        this.contactListItems = listItems;
    };
}
