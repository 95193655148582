import {Injectable} from '@angular/core';
import {AppService} from '../app/app.service';
import {AppQuery} from '../../queries/app.query';
import {ServerAddress} from '../../models/server-address';
import {BehaviorSubject, firstValueFrom, lastValueFrom, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {DialogService} from '../dialog/dialog.service';
import clientServerInformation from '../../../../server-information.json';
import {unsecureProtocol} from '../../constants/server/unsecure-protocol.constant';
import {secureProtocol} from '../../constants/server/secure-protocol.constant';
import {getDefaultServers} from '../../util/server/get-default-servers';
import {STATIC_CONFIGS} from '../../../configs/static.config';
import {AuthService} from '../auth/auth.service';
import {ServerInformation} from '../../api/models/server-information';
import {ServerInformation as BfaServerInformation} from '../../bfa-api/models/server-information';
import {TimeoutError} from '../../errors/timeout-error';
import {HttpError} from '../../errors/http-error';
import {distinctUntilChangedObject} from '../../util/distinct-until-changed-object';
import {LanguageService} from '../language/language.service';

@Injectable({
    providedIn: 'root'
})
export class ServerSelectionService {
    public selectedServer$: BehaviorSubject<ServerAddress | undefined>;
    public hasServerAddresses$: Observable<boolean>;
    public serverAddresses$: BehaviorSubject<Array<ServerAddress>>;
    public isCheckingForVersion: boolean;
    private previousServerAddresses: string;
    private lastSuccessfulProtocol: string;
    private lastSuccessfulUrlParts: Array<string>;
    private serverInformation: BfaServerInformation | undefined;

    constructor(
        private appQuery: AppQuery,
        private appService: AppService,
        private httpClient: HttpClient,
        private dialogService: DialogService,
        private authService: AuthService,
        private languageService: LanguageService,
    ) {
        this.previousServerAddresses = '';
        this.selectedServer$ = new BehaviorSubject<ServerAddress | undefined>(undefined);
        this.serverAddresses$ = new BehaviorSubject<Array<ServerAddress>>([]);
        this.hasServerAddresses$ = this.serverAddresses$.pipe(map(serverAddresses => serverAddresses.length > 0));
        this.lastSuccessfulProtocol = '';
        this.lastSuccessfulUrlParts = [];
        this.isCheckingForVersion = environment.production;

        this.appQuery.selectedServer$.pipe(distinctUntilChangedObject())
            .subscribe(async (serverAddress) => {
                if (serverAddress) {
                    await this.updateServerInformationByServerAddress(serverAddress);
                }
            });
    }

    public toggleVersionCheck(): void {
        this.isCheckingForVersion = !this.isCheckingForVersion;
    }

    public loadServers(): void {
        this.previousServerAddresses = JSON.stringify(this.appQuery.getServers());
        const serverAddresses: Array<ServerAddress> = JSON.parse(this.previousServerAddresses);
        let needToSave = false;
        // fix for already added connection without protocol
        for (const server of serverAddresses) {
            if (!this.urlHasValidProtocol(server.url)) {
                if (!('displayUrl' in server as unknown as Record<string, unknown>) || !server.displayUrl) {
                    server.displayUrl = server.url;
                }
                server.url = this.cleanUrl(secureProtocol + server.url + '/' + environment.apiUrlParts.join('/'), false);
                needToSave = true;
            }
        }
        this.serverAddresses$.next(serverAddresses);
        this.selectedServer$.next(this.getSelectedServer());
        if (needToSave) {
            this.saveServers();
        }
    }

    public async addServer(serverAddress: ServerAddress): Promise<boolean> {
        const loadingDialog = this.dialogService.showPermanentToast('SERVER_SELECTION.CHECKING_URL_MSG');
        const exists = await this.fullCheckServerExists(serverAddress.url);
        loadingDialog.dismiss();
        if (exists) {
            serverAddress.url = this.lastSuccessfulProtocol + this.cleanUrl([serverAddress.url, ...this.lastSuccessfulUrlParts].join('/'));
            this.addServerAddressToList(serverAddress);
            this.lastSuccessfulProtocol = '';
            this.lastSuccessfulUrlParts = [];
            await lastValueFrom(this.dialogService.showDialog({ messageKey: 'SERVER_SELECTION.CONNECTION_ADD_SUCCESS', appTestTag: 'server-added-successfully' })
                .afterClosed());
            this.loadServers();
            this.selectServer(serverAddress.displayUrl, false);
        }
        return exists;
    }

    public selectServer(url: string, deselect: boolean = true): void {
        const currentSelectedServer = this.getSelectedServer();
        let selectedServer;
        if (currentSelectedServer?.displayUrl !== url) {
            const serverAddresses = this.serverAddresses$.getValue();
            for (const server of serverAddresses) {
                if (server.displayUrl === url) {
                    server.selected = true;
                    selectedServer = server;
                } else {
                    server.selected = false;
                }
            }
        } else {
            if (deselect) {
                if (currentSelectedServer) {
                    currentSelectedServer.selected = false;
                }
            } else {
                selectedServer = currentSelectedServer;
            }
        }
        this.selectedServer$.next(selectedServer);
    }

    public getServers(): Array<ServerAddress> {
        return JSON.parse(JSON.stringify(this.serverAddresses$.getValue()));
    }

    public hasServerHost(url: string): boolean {
        return this.getServers()
            .filter(serverAddress => this.cleanUrl(serverAddress.displayUrl) === this.cleanUrl(url)).length > 0;
    }

    public removeServer(name: string | undefined, displayUrl: string): void {
        this.dialogService.showConfirmDialog({
                messageKey: 'SERVER_SELECTION.CONFIRM_REMOVE',
                confirmText: 'BUTTON.OK',
                cancelText: 'BUTTON.CANCEL',
                translateKeyParameters: { name: name || displayUrl },
                appTestTag: 'remove-server',
            })
            .then(removeServer => {
                if (removeServer) {
                    const filteredServerAddresses = this.getServers()
                        .filter(server => server.displayUrl !== displayUrl);
                    this.serverAddresses$.next(filteredServerAddresses);
                    this.selectedServer$.next(undefined);
                    this.saveServers();
                }
            });
    }

    public hasChanges(): boolean {
        const servers = JSON.stringify(this.getServers());
        return this.previousServerAddresses !== servers;
    }

    public saveServers(): void {
        this.appService.updateServers(this.getServers());
    }

    public cleanUrl(url: string, removeProtocol: boolean = true): string {
        let protocol;
        let cleanUrl;

        if (URL.canParse(url)) {
            const temporaryUrl = new URL(url);

            protocol = temporaryUrl.protocol;
            cleanUrl = temporaryUrl.host + temporaryUrl.pathname;
        } else {
            cleanUrl = url;
        }

        cleanUrl = cleanUrl
            .replace(/\/+/g, '/')
            .replace(/(\/$)|(^\/)/g, '')
            // Remove internal-API path segment from server URLs, due to downward compatibility
            // @see https://amagno.atlassian.net/browse/APP-1024
            .replace(new RegExp('/' + STATIC_CONFIGS.apis.internal.path + '$'), '');

        if (!removeProtocol && protocol) {
            cleanUrl = protocol + '//' + cleanUrl;
        }

        return cleanUrl;
    }

    public urlHasValidProtocol(url: string): boolean {
        return (url.includes(secureProtocol) || url.includes(unsecureProtocol));
    }

    public async checkServerVersion(serverVersion: string): Promise<boolean> {
        if (!this.isCheckingForVersion) {
            return true;
        }

        const [apiVersionMajor, apiVersionMinor] = this.getMajorAndMinor(serverVersion);

        // @TODO: Temporary fix for compatibility reasons. Remove as soon as possible! See https://amagno.atlassian.net/browse/APP-838
        // This should be eliminated and change for a "feature minimum backend version requirement" system
        return apiVersionMajor === 7 || (apiVersionMajor === 6 && apiVersionMinor >= 9);
    }

    public getExpectedServerVersion(): string {
        const [clientVersionMajor, clientVersionMinor] = this.getMajorAndMinor(clientServerInformation.serverVersion);
        return clientVersionMajor + '.' + clientVersionMinor;
    }

    public async fullCheckServerExists(url: string, checkUrlParts: boolean = true): Promise<boolean> {
        const cleanUrl = this.cleanUrl(url, false);
        let validUrl;

        if (URL.canParse(cleanUrl)) {
            validUrl = new URL(cleanUrl);
        } else {
            if (URL.canParse(secureProtocol + cleanUrl)) {
                validUrl = new URL(secureProtocol + cleanUrl);
            } else {
                this.dialogService.showDialog({ messageKey: 'SERVER_SELECTION.ERROR.NO_API_FOUND', appTestTag: 'connection-could-not-be-established-no-such-server' });

                return false;
            }
        }

        const urlWithProtocol = validUrl.toString().replace(/\/$/g, '');
        const urlWithoutProtocol = (validUrl.host + validUrl.pathname).replace(/\/$/g, '');

        try {
            if (await this.getServerInformation(urlWithoutProtocol)) {
                return true;
            }
            // No need to validate error, because only the return value is relevant for the consumer
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
        }

        try {
            if (await this.getServerInformation(urlWithProtocol)) {
                return true;
            }
            // No need to validate error, because only the return value is relevant for the consumer
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
        }

        if (!checkUrlParts) {
            return false;
        }

        const urlParts = await this.checkUrlParts(urlWithProtocol);
        let workingUrl = '';

        if (urlParts) {
            workingUrl = this.lastSuccessfulProtocol + this.cleanUrl([urlWithoutProtocol, ...urlParts].join('/'));
        }

        if (!!workingUrl) {
            if (this.serverInformation) {
                if (!await this.checkServerVersion(this.serverInformation.serverVersion)) {
                    this.dialogService.showDialog({
                        messageKey: 'SERVER_SELECTION.ERROR.NOT_MATCHING_VERSION',
                        appTestTag: 'invalid-server-version',
                        translateKeyParameters: { version: this.getExpectedServerVersion() }
                    });
                    return false;
                }
                return true;
            }
        } else {
            this.dialogService.showDialog({ messageKey: 'SERVER_SELECTION.ERROR.NO_API_FOUND', appTestTag: 'connection-could-not-be-established-no-such-server' });
        }

        return false;
    }

    public addDefaultServerAddress(): void {
        const defaultServers = getDefaultServers();
        this.appService.updateServers(defaultServers);
    }

    /**
     * @throws TimeoutError on API connection timeout of last tried connection endpoint
     * @throws HttpError when last tried connection endpoint returns an error
     * @throws Error on errors that occurred while computing the API response of last tried connection endpoint
     */
    public async getServerInformation(url: string, timeoutInMs?: number): Promise<BfaServerInformation> {
        return new Promise(async (resolve, reject) => {
            let numberOfConnectionErrors = 0;
            const serverInformationUrls = [
                url + '/' + STATIC_CONFIGS.apis.bfa.path + '/' + STATIC_CONFIGS.paths.serverInformation,
                url + '/' + STATIC_CONFIGS.paths.serverInformation
            ];

            for (const serverInformationUrl of serverInformationUrls) {
                try {
                    const serverInformation = await this.fetchServerInformation(serverInformationUrl, timeoutInMs);

                    this.serverInformation = serverInformation;
                    return resolve(serverInformation);
                } catch (error) {
                    if (++numberOfConnectionErrors === serverInformationUrls.length) {
                        this.serverInformation = undefined;
                        return reject(error);
                    }
                }
            }
        });
    }

    public increaseLoginCount(displayUrl: string): void {
        // This action is performed to make sure that the active server addresses are used.
        // Without this, notifications aren´t updated correctly when logging in. See: https://amagno.atlassian.net/browse/APP-1268
        this.serverAddresses$.next(this.appQuery.getServers());

        const servers = this.getServers();
        const selectedServer = servers.find(server => server.displayUrl === displayUrl);
        if (selectedServer) {

            if (!('loginCount' in (selectedServer as unknown as Record<string, unknown>))) {
                selectedServer.loginCount = 0;
            }
            selectedServer.loginCount += 1;
            this.serverAddresses$.next(servers);
            this.saveServers();
        }
    }

    public extractProtocolFromUrl(url: string): string | undefined {
        const protocolRegex = /http[s]?:\/\//gm;
        const regexMatches = protocolRegex.exec(url);
        if (regexMatches) {
            return regexMatches[0];
        }
        return undefined;
    }

    public connectionLost(): void {
        const selectedServer = this.appQuery.getSelectedServerUrl();
        if (selectedServer) {
            this.dialogService.showConnectionLostDialog(async () => {
                    try {
                        return !!(await this.getServerInformation(selectedServer));

                        // No need to validate error, because the showConnectionLostDialog() itself contains the error message for the user
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    } catch (error) {
                        return true;
                    }
                })
                .then(async connectionReestablished => {
                    if (connectionReestablished) {
                        this.dialogService.showSuccess('ERROR.CONNECTION_LOST.SUCCESS');
                        this.appService.setIsDisconnected(false);
                    } else {
                        this.connectionLost();
                    }
                })
                .catch(async () => {
                    await this.authService.logoutWithoutNavigation();
                    window.location.href = '/auth/login';
                });
        }
    }

    public async loadServerInformation(serverAddress: ServerAddress): Promise<ServerAddress> {
        const updatedServerAddress = structuredClone(serverAddress);

        let serverInformation: BfaServerInformation;
        let serverIsOutdated = false;
        let serverIsOffline = false;

        try {
            serverInformation = await this.getServerInformation(serverAddress.url);
            serverIsOutdated = !(await this.checkServerVersion(serverAddress.serverInformation.serverVersion));

            // No need to validate error, because the updatedServerAddress contains the relevant information for consumers
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
            serverIsOffline = true;
            serverInformation = {
                serverVersion: '',
                maintenanceMessage: undefined,
                notification: undefined,
                systemMessage: undefined,
                registrationLinkUrl: undefined,
                apiVersion: ''
            };
        }

        updatedServerAddress.isOutdated = serverIsOutdated;
        updatedServerAddress.isOffline = serverIsOffline;
        updatedServerAddress.serverInformation = serverInformation;

        return updatedServerAddress;
    }

    public async updateServerInformationByServerAddress(serverAddress: ServerAddress): Promise<void> {
        const serverInformation = await this.getServerInformation(serverAddress.url);

        if (serverInformation) {
            const updatedServerAddress: ServerAddress = {
                ...serverAddress,
                serverInformation,
            };

            this.appService.updateServers(this.appQuery.getServers()
                .map(serverAddress => serverAddress.selected ? updatedServerAddress : serverAddress));
        }
    }

    private getMajorAndMinor(version: string): Array<number> {
        const versionParts = version.split('.');
        return versionParts.map(versionPart => Number(versionPart));
    }

    private mapServerInformationToBfaServerInformation(serverInformation: ServerInformation | BfaServerInformation): BfaServerInformation {
        return {
            apiVersion: ('apiVersion' in serverInformation) ? serverInformation.apiVersion : '',
            systemMessage: ('systemMessage' in serverInformation) ? serverInformation.systemMessage : undefined,
            registrationLinkUrl: ('registrationLinkUrl' in serverInformation) ? serverInformation.registrationLinkUrl : undefined,
            maintenanceMessage: ('maintenanceMessage' in serverInformation) ? serverInformation.maintenanceMessage : undefined,
            serverVersion: ('serverVersion' in serverInformation) ? serverInformation.serverVersion : '',
            notification: ('notification' in serverInformation) ? serverInformation.notification : undefined
        };
    }

    /**
     * @throws TimeoutError on API connection timeout
     * @throws HttpError on error response from API
     * @throws Error on errors that occurred while computing the API response
     */
    private async fetchServerInformation(url: string, timeoutInMs?: number): Promise<BfaServerInformation> {
        timeoutInMs = timeoutInMs || STATIC_CONFIGS.apiTimeoutsInMs.serverInformation;

        return new Promise(async (resolve, reject) => {
            const urlHasValidProtocol = this.urlHasValidProtocol(url);
            const serverInformationUrl = (urlHasValidProtocol ? url : secureProtocol + url) + '?locale=' + this.languageService.getLanguage();
            let timeOutTimer;

            this.lastSuccessfulProtocol = urlHasValidProtocol ? this.extractProtocolFromUrl(url) || '' : secureProtocol;

            try {
                if (timeoutInMs) {
                    timeOutTimer = setTimeout(() => {
                        reject(new TimeoutError(timeoutInMs, `Timeout error in URL: "${serverInformationUrl}" after ${timeoutInMs}ms`));
                    }, timeoutInMs);
                }

                const serverInformation = await firstValueFrom(this.httpClient.get<BfaServerInformation | ServerInformation>(serverInformationUrl));
                const bfaServerInformation = this.mapServerInformationToBfaServerInformation(serverInformation);

                if (timeoutInMs) {
                    clearTimeout(timeOutTimer);
                }

                resolve(bfaServerInformation);
            } catch (error) {
                if (timeoutInMs) {
                    clearTimeout(timeOutTimer);
                }

                if (error instanceof HttpErrorResponse) {
                    reject(new HttpError(error.status, error.message));
                } else {
                    reject(error);
                }
            }
        });
    }

    private async checkUrlParts(serverUrl: string): Promise<Array<string> | undefined> {
        const createAndCheckServerInformationUrls = async (protocol: string): Promise<Array<string> | undefined> => {
            const urlMiddleParts = [...environment.apiUrlParts];
            let index = urlMiddleParts.length;

            for (; index > 0; index--) {
                const urlParts = [serverUrl, ...urlMiddleParts];
                console.log(index, urlParts);
                const url = protocol + this.cleanUrl(urlParts.join('/'));
                console.log(url);

                try {
                    if (await this.getServerInformation(url)) {
                        this.lastSuccessfulProtocol = protocol;
                        this.lastSuccessfulUrlParts = urlMiddleParts;

                        return urlMiddleParts;
                    }
                } catch (error) {
                    console.error(error); // @TODO: Only in dev environments
                }

                urlMiddleParts.shift();
            }

            return undefined;
        };

        return await createAndCheckServerInformationUrls(secureProtocol);
    }

    private addServerAddressToList(serverAddress: ServerAddress): void {
        const serverAddresses = this.getServers();
        serverAddresses.push(serverAddress);
        this.serverAddresses$.next(serverAddresses);
        this.saveServers();
    }

    private getSelectedServer(): ServerAddress | undefined {
        return this.serverAddresses$
            .getValue()
            .filter(server => server.selected)
            .pop();
    }
}
