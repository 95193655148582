import {EventEmitter, Inject, Injectable, OnDestroy} from '@angular/core';
import {TokenService as ApiTokenService} from '../../api/services/token.service';
import {AppService} from '../app/app.service';
import {AuthQuery} from '../../queries/auth.query';
import {AuthState, AuthStore, createInitialState} from '../../stores/auth.store';
import {DOCUMENT} from '@angular/common';
import {DialogService} from '../dialog/dialog.service';
import {NavigationService} from '../navigation/navigation.service';
import {UserService} from '../user/user.service';
import {Router} from '@angular/router';
import {resetStores} from '@datorama/akita';
import {firstValueFrom, Subscription} from 'rxjs';
import {MeService as ApiMeService} from '../../api/services/me.service';
import {take} from 'rxjs/operators';
import {HistoryService} from '../history/history.service';
import {protect, unprotect} from '../../util/protect';
import {AppQuery} from '../../queries/app.query';
import {environment} from '../../../environments/environment';
import {PermissionService} from '../permission/permission.service';
import {LanguageService} from '../language/language.service';
import {UserQuery} from '../../queries/user.query';
import {User} from '../../api/models/user';
import {NotificationService} from '../notification/notification.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements OnDestroy {
    subscriptions: { [func: string]: Subscription | undefined };
    private tokenRefreshTimer: ReturnType<typeof setTimeout> | null;
    private afterLoginNavigation$: EventEmitter<boolean>;

    constructor(
        private authQuery: AuthQuery,
        private appQuery: AppQuery,
        private appService: AppService,
        private authStore: AuthStore,
        private tokenService: ApiTokenService,
        private apiMeService: ApiMeService,
        private dialogService: DialogService,
        @Inject(DOCUMENT) private document: Document,
        private navigationService: NavigationService,
        private router: Router,
        private historyService: HistoryService,
        private permissionService: PermissionService,
        private languageService: LanguageService,
        private userService: UserService,
        private userQuery: UserQuery,
        private notificationService: NotificationService,
    ) {
        this.tokenRefreshTimer = null;
        this.subscriptions = {};
        this.afterLoginNavigation$ = new EventEmitter<boolean>();
    }

    ngOnDestroy(): void {
        Object.values(this.subscriptions)
            .filter(s => s !== undefined)
            .forEach(s => s?.unsubscribe());
    }

    // @TODO: Should be removed with tryToRelog when backend has refresh tokens
    public async tryToRelog(checkRememberUserFlag: boolean = false): Promise<boolean> {
        if (this.document.location.href.indexOf('logout') !== -1) {
            return false;
        }

        const userName = await unprotect(this.authQuery.getUserName());
        const password = await unprotect(this.authQuery.getPassword());
        const isRememberUser = this.authQuery.isRememberUser();

        if (checkRememberUserFlag && !isRememberUser) {
            return false;
        }

        if (userName.length > 0 && password.length > 0) {
            await this.login(userName, password, isRememberUser);
            return this.authQuery.getIsLoggedIn();
        }

        return false;
    }

    public async login(userName: string, password: string, rememberUser: boolean = false): Promise<void> {
        this.appService.showSpinner();
        try {
            let tokenString = await firstValueFrom(this.tokenService.TokenCreate({
                userName,
                password
            }));

            if (tokenString.startsWith('"') && tokenString.endsWith('"')) {
                tokenString = tokenString.substring(1, tokenString.length - 1);
            }

            const tokenParts = tokenString.split('.');
            const tokenData = JSON.parse(atob(tokenParts[1]));

            // we need to calculate a local expire time for devices with wrong time settings
            const expireIntervalInSeconds = parseInt(tokenData.exp, 10) - parseInt(tokenData.nbf, 10);
            const localExpireDate = Date.now() + (expireIntervalInSeconds * 1000);

            this.authStore.update({
                userName: protect(userName),
                password: protect(password),
                token: tokenString,
                tokenExpiresTs: localExpireDate,
                userId: tokenData.UserId,
                isLoggedIn: true,
                rememberUser,
            });
            await this.userService.fetchUser(tokenData.UserId);
            const user = this.userQuery.getUserById(tokenData.UserId) as User;

            this.authStore.update({
                fullName: user.fullName || userName,
                languageCultureName: user.languageCultureName,
            });
            await this.permissionService.fetchMePermission();
            this.scheduleTokenRefresh();
        } catch (err) {
            this.dialogService.showError('AUTH_CREDENTIAL_ERROR_MSG', err as Error);
        } finally {
            this.authStore.update({
                failedLoginAttempts: 0,
            });
            this.appService.hideSpinner();
        }
    }

    public async loginWithNavigation(userName: string, password: string, rememberUser: boolean = false): Promise<void> {
        await this.login(userName, password, rememberUser);
        const link = ['me', 'vaults'];
        await this.navigationService.navigate(link, { state: { fromLogin: true } });
        const selectedUserId = this.userQuery.getSelectedUser()?.id;
        this.historyService.addNavigationHistory({ title: 'ME', subTitle: 'VAULTS', icon: '/users/' + selectedUserId + '/icon?size=Medium', }, link);
        this.afterLoginNavigation$.emit(true);
    }

    public async logout(): Promise<void> {
        await this.logoutWithoutNavigation();
        await this.router.navigate(['/', 'auth', 'logout']);
    }

    public async logoutWithoutNavigation(): Promise<void> {
        if (this.tokenRefreshTimer !== null) {
            clearTimeout(this.tokenRefreshTimer);
        }
        this.updateAuthToken(createInitialState());
        resetStores({
            exclude: ['app', 'tutorial', 'browser']
        });
        // reset all akita stores https://datorama.github.io/akita/docs/additional/reset/
        this.appService.resetOnLogout();
        await this.languageService.setCurrentLanguage();
        this.appService.resetLoadSpinner();
        this.appService.showDebugMenu();
    }

    public async setup(): Promise<void> {
        // always logout user when the version has been changed
        const currentStoreVersion = this.appQuery.getCurrentVersion();
        const currentVersion = environment.version;

        if ((currentStoreVersion === '' && this.authQuery.getIsLoggedIn()) || currentStoreVersion !== '' && currentStoreVersion !== currentVersion) {
            this.appService.setCurrentVersion(currentVersion);
            await this.logout();
            return;
        }

        try {
            // @TODO: Remove tryToRelog and password from store, then check timestamp if refresh available, else logout
            if (!await this.tryToRelog(true)) {
                if (this.authQuery.hasToken()) {
                    await this.logout();
                    await this.navigationService.navigate(['auth', 'login']);
                }

                this.notificationService.setHasReadNotificationInSession(false);
            } else {
                const selectedServer = this.appQuery.getSelectedServer();
                const serverInformation = selectedServer?.serverInformation;

                if (serverInformation?.systemMessage) {
                    await this.dialogService.showConfirmDialog({
                        message: serverInformation.systemMessage,
                        title: 'SYSTEM_MESSAGE_TITLE',
                        confirmText: 'BUTTON.OK',
                        appTestTag: 'system-message'
                    }, { disableClose: true });
                }

                if (serverInformation) {
                    this.notificationService.updateNotification();
                    this.notificationService.setHasReadNotificationInSession(false);
                }

                this.scheduleTokenRefresh();
            }
        } catch (err) {
            this.dialogService.showError('CANT_RELOG_USER', err as Error);
            await this.logout();
            await this.navigationService.navigate(['auth', 'login']);
        }
    }

    public continueAfterLoggedIn(func: () => Promise<boolean>): void {
        // if already logged in then just execute the function
        if (this.authQuery.getIsLoggedIn()) {
            func()
                .then();
            return;
        }

        const funcStr = func.toString();
        if (!(funcStr in this.subscriptions)) {
            this.subscriptions[funcStr] = undefined;
        }
        // get rid of current subscription
        if (this.subscriptions[funcStr] !== undefined) {
            this.subscriptions[funcStr]?.unsubscribe();
        }

        this.subscriptions[funcStr] = this.authQuery.token$.subscribe(async token => {
            if (token && token.length > 0) {
                const isAfterLogin = await firstValueFrom(this.afterLoginNavigation$.pipe(take(1)));
                if (!isAfterLogin) {
                    return;
                }
                if (await func()) {
                    if (this.subscriptions[funcStr] !== undefined) {
                        this.subscriptions[funcStr]?.unsubscribe();
                    }
                }
            }
        });
    }

    public async resetPassword(email: string): Promise<boolean> {
        let isReset = false;
        this.appService.showSpinner();
        try {
            await firstValueFrom(this.apiMeService.MeResetPassword({ userName: email }));
            isReset = true;
        } catch (err) {
            console.error(err);
        } finally {
            this.appService.hideSpinner();
        }
        return isReset;
    }

    public invalidateToken(): void {
        this.authStore.update({ token: 'invalid_token' });
    }

    public setCultureName(languageCultureName: string): void {
        this.authStore.update({ languageCultureName });
    }

    private updateAuthToken(authToken: AuthState): void {
        this.authStore.update(authToken);
    }

    private scheduleTokenRefresh(): void {
        if (this.tokenRefreshTimer !== null) {
            clearTimeout(this.tokenRefreshTimer);
        }
        const tokenExpiresTs = this.authQuery.getTokenExpiresTs();
        if (tokenExpiresTs > 0) {
            this.tokenRefreshTimer = setTimeout(async () => {
                await this.tryToRelog();
            }, (tokenExpiresTs - Date.now()) - 60000);
        }
    }
}
